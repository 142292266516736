<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      productSlug: {
        type: String,
        default: undefined,
      },
    },
    data() {
      return {
        quantity: 1,
      }
    },
    computed: {
      ...get('marketplace', ['products']),
      ...get('marketplaceCart', ['cartQuantity']), // can use cart quantity since only 1 sku can be added to the cart
      screen,
      product() {
        return this.products.find((product) => product.slug === this.productSlug)
      },
      defaultVariant() {
        return this.product?.defaultVariant
      },
      productImage() {
        return this.product?.images?.[0]
      },
      productPrice() {
        return this.defaultVariant?.price
      },
      percentOff() {
        const discountAmount = this.product?.retailValue - this.productPrice
        return Math.round((discountAmount / this.product?.retailValue) * 100)
      },
      quantitySelectionOptions() {
        const maxQuantity = this.defaultVariant?.maxQuantity ?? 10
        const quantityAllowedToAdd = Math.max(maxQuantity - this.cartQuantity, 0)
        return [...Array(quantityAllowedToAdd).keys()].map((i) => i + 1)
      },
    },
    methods: {
      ...call('marketplaceCart', ['addToCart']),
      productData(prop) {
        return this.product?.[prop] ?? ''
      },
      async addAndNavigateToCart() {
        await this.addToCart({
          variantId: this.defaultVariant.id,
          quantity: this.quantity,
        })
        this.$navigate({ name: 'MarketplaceCart' })
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="product" class="pb-18">
      <div class="my-2 lg:my-6 text-xs text-center">
        <span v-if="!screen.md"> Avaiable Now through Monday! </span>
        <span v-else> Now through Monday, get a members-only Mystery Gift Box! </span>
      </div>
      <div class="px-2 lg:px-0">
        <BaseDivider color="dawn-dk2" class="max-w-200 mb-4 lg:mb-6" />
      </div>
      <div class="mb-14 flex flex-col lg:flex-row justify-center max-w-140 lg:max-w-200 mx-auto">
        <!-- Mobile header -->
        <div v-if="!screen.lg" class="mb-6 flex flex-col items-center text-center">
          <span class="border border-black font-bold text-2xs rounded leading-close px-2 py-3px">
            MEMBERS-ONLY
          </span>
          <h1 class="my-3 font-heading font-semibold text-4xl leading-none">
            {{ productData('title') }}
          </h1>
          <div class="flex items-center text-lg leading-none">
            <span class="font-semibold">
              {{ $formatPrice(productPrice) }}
            </span>
            <s class="mx-9px">
              {{ $formatPrice(productData('retailValue')) }}
            </s>
            <span
              v-if="percentOff > 0"
              class="p-1 font-bold text-2xs uppercase tracking-wide bg-primary text-dawn-lt3"
            >
              {{ percentOff }}% off
            </span>
          </div>
        </div>
        <!-- Product image -->
        <BaseImage
          :src="productImage"
          :alt="productData('title')"
          background-transparent
          class="flex-1 lg:mr-17"
        />
        <div class="flex flex-col lg:flex-1 lg:w-full lg:max-w-97 px-2 lg:px-0 lg:ml-4">
          <!-- Desktop header and description -->
          <template v-if="screen.lg">
            <div>
              <span
                class="border border-black font-bold text-2xs rounded leading-close px-2 py-3px"
              >
                MEMBERS-ONLY
              </span>
              <h1 class="mt-3 font-heading font-semibold text-4xl leading-none">
                {{ productData('title') }}
              </h1>
            </div>
            <BaseRichText font-size="xs" class="my-10px" v-html="productData('description')" />
            <div class="mb-6 flex items-center text-lg leading-none">
              <span class="font-semibold">
                {{ $formatPrice(productPrice) }}
              </span>
              <s class="mx-9px">
                {{ $formatPrice(productData('retailValue')) }}
              </s>
              <span
                v-if="percentOff > 0"
                class="p-1 font-bold text-2xs uppercase tracking-wide bg-primary text-dawn-lt3"
              >
                {{ percentOff }}% off
              </span>
            </div>
          </template>
          <div
            class="z-20 fixed inset-x-0 bottom-0 lg:static lg:inset-auto px-4 py-4 lg:p-0 border-t border-gray-active lg:border-0 bg-dawn-lt3"
          >
            <div class="flex items-center">
              <div class="mr-4 w-24 flex-shrink-0">
                <BaseInput
                  v-model="quantity"
                  type="select"
                  is-pdp-selector
                  :options="quantitySelectionOptions"
                />
              </div>
              <div class="flex-grow">
                <BaseButton @click="addAndNavigateToCart">
                  <span class="font-semibold text-lg">
                    Buy now&#8211;{{ $formatPrice(productPrice) }}
                  </span>
                </BaseButton>
              </div>
            </div>
          </div>

          <!-- Mobile description -->
          <BaseRichText
            v-if="!screen.lg"
            font-size="xs"
            class="mt-6 mb-4"
            v-html="productData('description')"
          />
          <!-- Product specs in accordion -->
          <div class="lg:mt-26px border-t border-b border-black">
            <BaseAccordion button-inner-classes="py-4">
              <template v-slot:buttonLabel>
                <span class="font-bold">DETAILS</span>
              </template>
              <BaseRichText font-size="xs" class="mb-4" v-html="productData('specs')" />
            </BaseAccordion>
          </div>
        </div>
      </div>
    </div>
    <BaseSpinner v-else overlay="fixed" />
  </div>
</template>
